// @import '@thg-harveynash/cp-hyper-react-package-ui/dist/assets/scss/Saas.scss';
// @import 'primereact/resources/themes/lara-light-indigo/theme.css';
// @import 'primereact/resources/primereact.min.css';
// @import '../primereact/themes/lara-dark-blue.scss';
// @import './icons/dripicons.scss';
// @import './icons/materialdesignicons.scss';
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400&display=swap');
// @import './components/react-select.scss';
@import './components/circle-progress.scss';
// @import './components/rightside-popup.scss';
// @import "~primereact/resources/themes/vela-blue/theme.css";

@import '~primereact/resources/primereact.min.css';

// @import "~primeicons/primeicons.css";

@import '~@thg-harveynash/hyper-shared-components/build/scss/Saas.scss';

:root {
  --ct-success-rgb: 25, 135, 84;
  --ct-danger-rgb: 220, 53, 69;
  --ct-warning-rgb: 255, 193, 7;
  --layout-menu-item-border-color: hsla(0, 0%, 100%, 0.15);
  --layout-sidebar-bg-color: #fff;
  --layout-sidebar-color: #fff;
  --layout-menu-item-active: #605bff;
  --ct-body-font-size: 1rem !important;
  --layout-primary-system-color: #031daa;
  --layout-input-search-cancel: url('data:image/svg+xml;utf8,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27black%27><path d=%27M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z%27/></svg>');
}

body[data-sidebar-theme='default'] {
  --layout-input-border-color: #cccccc;
  --layout-input-placeholder-color: #cccccc;

  .sidebar-search .form-control::placeholder {
    color: #cccccc;
    opacity: 1;
  }

  .sidebar-search .form-control :-ms-input-placeholder {
    color: #cccccc;
  }

  .sidebar-search .form-control ::-ms-input-placeholder {
    color: #cccccc;
  }
}

.p-row-toggler {
  color: #333333 !important;
}

body[data-sidebar-theme='light'] {
  .sidebar-search .form-control::placeholder {
    color: #666666;
    opacity: 1;
  }

  .sidebar-search .form-control :-ms-input-placeholder {
    color: #666666;
  }

  .sidebar-search .form-control ::-ms-input-placeholder {
    color: #666666;
  }
}

.form-check-label {
  font-weight: 500;
}

.breadcrumb {
  margin-bottom: 0px;
  font-size: 15px;
}

.approver-select .p-multiselect-trigger {
  width: 40px !important;
}

.p-multiselect {
  width: 100%;
  min-height: 38px;
  font-size: 13.6px !important;
  border: 1px solid var(--ct-input-border-color) !important;
  background-color: var(--ct-input-bg) !important;
  border-radius: 4px;
  box-shadow: none !important;
  align-items: center;

  .p-multiselect-trigger {
    width: 36px;
  }

  .selected-item-value {
    // background-color: rgba(90, 105, 235, 0.2) !important;
    background-color: var(--ct-form-check-input-checked-bg-color) !important;
    color: #fff !important;
    border-radius: 2px !important;
    padding: 3px 6px !important;
    margin: 2px !important;
    display: flex;
    font-size: 90%;

    svg {
      fill: #fff !important;
    }
  }

  .p-multiselect-label {
    padding: 3px 30px 0px 10px !important;
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    color: hsl(0, 0%, 50%) !important;
  }

  .time-icon {
    margin-left: 3px;
  }

  .p-placeholder {
    font-size: 15px;
  }

  .p-multiselect-close {
    display: none;
  }
}

.p-multiselect .p-multiselect-clear-icon,
.p-multiselect-clear-icon,
.p-multiselect-filter-icon {
  color: #8c8c8c !important;
  font-size: 16px !important;
  cursor: pointer;
}

.p-multiselect .p-multiselect-clear-icon::before {
  font-size: 80% !important;
}

.p-multiselect-clear-icon {
  .pi-times {
    color: hsl(0, 0%, 79%) !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-top: -5px;
  }
}

.max-width-none {
  max-width: none !important;
}

.default-hide {
  max-width: 200px !important;
}

.expand-row-toggle {
  max-width: 50px !important;
  width: 5rem;
}

.p-multiselect-filter {
  height: 38px;
}

.p-virtualscroller {
  min-height: 300px;
}

.p-virtualscroller .p-checkbox {
  display: none;
}

.p-multiselect-clear-icon:hover {
  color: hsl(0, 0%, 60%) !important;
}

.p-multiselect-close:enabled:hover {
  color: hsl(0deg, 0%, 60%) !important;
  background: none !important;
}

.p-inputtext:enabled:hover {
  box-shadow: none !important;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border: 1px solid var(--ct-input-border-color) !important;
}

.p-multiselect-empty-message {
  padding-top: 3px !important;
}

.p-multiselect-close:focus {
  box-shadow: none !important;
}

.p-multiselect-panel {
  padding: 0 !important;
  background-color: var(--ct-input-bg) !important;
  border: 1px solid var(--ct-input-border-color) !important;

  .p-multiselect-item:focus {
    box-shadow: none !important;
  }

  .p-multiselect-items .p-multiselect-item:hover {
    background-color: var(--ct-primary-lightest) !important;
  }

  .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: rgba(90, 105, 235, 0.2) !important;
  }
}

.p-multiselect-header {
  background-color: var(--ct-input-bg) !important;
  border: 1px solid var(--ct-input-border-color) !important;
}

.p-multiselect-footer {
  position: absolute;
  bottom: 150px;
  left: 30px;
}

.dropdown-select {
  fill: #98a6ad !important;
}

.detail-info {
  align-items: center;
}

.notification-list {
  padding: 0;
  margin: 0;
  margin-right: 5px;
  list-style: none;
}

html * {
  font-family: 'Nunito', sans-serif;
}

.side-nav > .side-nav-item:before {
  background-color: rgba(0, 0, 0, 0.1);
}

// .sidebar-search .form-control {
//   border: 1px solid #8c8c8c !important;
// }

body[data-sidebar-theme='default'] .layout_sidebar {
  background: linear-gradient(135deg, #8f75da 0%, #727cf5 60%) !important;
}

body[data-sidebar-theme='dark'] .layout_sidebar {
  background: #21242b !important;
}

.layout .side-nav .side-nav-item.menuitem-active > a.active {
  padding-left: 60px !important;
}

.layout--collapsed .side-nav .side-nav-item.menuitem-active > a.active {
  padding-left: 25px !important;
}

body[data-leftbar-theme='dark'] .logo-menu {
  color: var(--ct-dark-menu-item-active) !important;
}

body[data-leftbar-theme='default'] .logo-menu {
  color: var(--ct-menu-item-active) !important;
}

body[data-leftbar-theme='light'] .logo-menu {
  color: var(--ct-light-menu-item-active) !important;
}

.wrapper .leftside-menu {
  position: fixed !important;
}

// .side-nav .menu-arrow:before {
//   content: '';
// }

.text-task {
  color: #313a46;
}

.project-detail-badge {
  width: auto;
}

.break-line {
  white-space: pre-wrap;
}

.p-row-toggler:focus {
  box-shadow: none !important;
}

.p-sub-table .p-datatable-thead {
  display: none;
}

.p-sub-table .p-paginator-bottom {
  display: none;
}

.p-sub-table .card {
  margin-bottom: 0;
}

.p-datatable-row-expansion > td {
  padding: 0 !important;
}

.cp-data-table {
  .p-datatable .p-sortable-column.p-highlight,
  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #f8f9fa;
    color: #343a40;
  }

  .p-datatable .p-datatable-header {
    border-radius: 4px 4px 0 0;
  }

  .p-datatable .p-paginator-bottom {
    border-radius: 4px !important;
    border-bottom: none !important;
  }

  .p-datatable .p-sortable-column:focus {
    box-shadow: none !important;
  }

  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon,
  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #343a40;
  }

  .p-button.p-button-icon-only {
    width: 38px;
    height: 38px;
  }

  .p-button.p-button-text {
    background-color: #727cf5;
    color: #fff;
  }

  .p-button.p-button-text:enabled:hover,
  .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: #727cf5;
    color: #fff;
  }

  // .p-paginator .p-paginator-first {
  //   border-top-left-radius: 0.25rem !important;
  //   border-bottom-left-radius: 0.25rem !important;
  // }

  // .p-paginator .p-paginator-first,
  // .p-paginator .p-paginator-prev,
  // .p-paginator .p-paginator-next,
  // .p-paginator .p-paginator-last,
  // .p-paginator .p-paginator-pages .p-paginator-page {
  //   border-radius: 0px;
  //   border: 1px solid #dee2e6;
  //   height: 38px;
  //   margin: 0;
  // }

  // .p-paginator .p-paginator-last {
  //   border-top-right-radius: 0.25rem;
  //   border-bottom-right-radius: 0.25rem;
  //   border-left: none;
  // }

  // .p-paginator .p-dropdown {
  //   height: 38px;
  //   display: flex;
  //   align-items: center;

  //   .pi {
  //     font-size: 14px;
  //   }
  // }

  // .p-paginator .p-paginator-pages .p-paginator-page {
  //   min-width: 38px;
  //   border-left: none;
  // }

  .p-link:focus {
    box-shadow: none;
  }

  // .p-paginator .p-paginator-prev,
  // .p-paginator .p-paginator-next,
  // .p-paginator-pages .p-paginator-page {
  //   border-left: none;
  // }

  // .p-datatable-scrollable .p-datatable-thead > tr > th,
  // .p-datatable-scrollable .p-datatable-tbody > tr > td,
  // .p-datatable-scrollable .p-datatable-tfoot > tr > td {
  //   font-size: 15px;
  // }

  .p-button:focus {
    box-shadow: none !important;
  }

  // .p-datatable-scrollable .p-datatable-thead > tr > th,
  // .p-datatable-scrollable .p-datatable-tbody > tr > td {
  //   display: block;
  //   overflow: hidden;
  //   white-space: nowrap;
  //   text-overflow: ellipsis;
  // }

  .p-datatable .p-datatable-tbody > tr.p-datatable-emptymessage > td {
    text-align: center;
  }

  .text-break {
    white-space: pre-line;
  }

  .ps-6 {
    padding-left: 3.2rem;
  }
}

.issue-expand {
  min-height: 22.5px;
}

.approver-select {
  min-height: 39.5px;
}

.approver-select .pi {
  font-size: 10px;
  color: #818c96;
}

.approver-select .p-multiselect-token-label {
  line-height: 1;
}

.select-error {
  .react-select__control {
    border-color: #fa5c7c !important;
  }

  .react-select__placeholder,
  .react-select__indicator {
    color: #fa5c7c !important;
  }
}

.multi-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #fa5c7c;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  border-color: #727cf5 !important;
  box-shadow: none !important;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 7px;
  height: 7px;
}

.p-radiobutton .p-radiobutton-box {
  width: 17px;
  height: 17px;
}

.p-radiobutton {
  width: 17px;
  height: 17px;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #727cf5;
  background: #727cf5;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #727cf5;
  background: #727cf5;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #ced4da;
}

body[data-layout-color='light'] {
  .p-calendar > input {
    color: #333333 !important;
  }

  .layout_header {
    .dropdown {
      button {
        background-color: #fff !important;
        box-shadow: none !important;
        border: none !important;
      }
    }
  }

  .avatar-styled {
    background-color: #1f5b8f;
    color: #fff;
  }

  .help-content {
    .p-accordion-header-link {
      color: #333333 !important;
    }

    .p-accordion-content {
      padding: 6px 0 !important;
      background: #fff !important;
    }

    .p-accordion-toggle-icon {
      font-weight: 700 !important;
      color: #605bff !important;
      font-size: 11px !important;
      margin: 0 3px !important;
    }

    .sub-item {
      .p-accordion-header-link {
        border-color: rgb(222, 226, 230) !important;
      }
    }

    .p-accordion-header-text {
      color: #333333 !important;

      &:hover {
        color: #495057 !important;
      }
    }

    .form-instructions-accordion-text {
      color: #495057 !important;
      font-family: 'Nunito Sans', sans-serif !important;
    }
  }

  .rightsidebar-content-height {
    .p-datepicker table td.p-datepicker-today > span.p-highlight {
      color: #333333;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
      background: var(--layout-menu-item-active);
      color: #fff;
    }

    .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
      background: #fff;
      border-color: var(--ct-input-border-color);
    }

    .p-datepicker .p-datepicker-header .p-datepicker-prev,
    .p-datepicker .p-datepicker-header .p-datepicker-next,
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
      color: #333333;
      font-size: 14px !important;

      &:hover {
        color: var(--layout-menu-item-active);
      }
    }

    .p-datepicker-calendar-container,
    .p-datepicker table,
    .p-datepicker:not(.p-datepicker-inline) {
      background: #fff;
      color: #333333;
      border-color: var(--ct-input-border-color);
    }

    .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
      background: var(--layout-menu-item-active);
      color: #fff;
    }
  }

  .content-page {
    color: var(--ct-body-color) !important;
    background-color: var(--ct-body-bg) !important;
  }

  .site-rightbar-ref .p-inputtext {
    color: hsl(0, 0%, 20%) !important;
    background: #f1f3f9 !important;
  }

  .p-multiselect-header {
    background-color: #fff !important;
    color: var(--ct-input-color) !important;
  }

  .p-multiselect-item {
    color: var(--ct-input-color) !important;
    font-size: 14px !important;
  }

  .p-multiselect-panel {
    background-color: #fff !important;
  }

  .p-input-area {
    color: #495057;
    background: #ffffff;
    border: 1px solid #ced4da;

    &:hover {
      border: 1px solid #605bff;
    }

    &::placeholder {
      color: hsl(0, 0%, 50%) !important;
    }
  }

  .welcome-session {
    h2,
    p {
      color: black !important;
    }
  }

  .form-container {
    .bg-nav-pills {
      background-color: #f5f5f5;
    }
    .nav-link:not(.active) {
      color: #5652e6;
    }
  }

  td a {
    color: #5652e6 !important;
  }
}

body[data-layout-color='dark'] {
  --ct-link-color: #807cff;
  --ct-link-hover-color: #706bff;
  --ct-btn-link-color: #605bff;

  .nav-bordered{
    .nav-link:not(.active) {
      color: rgb(114, 124, 245) !important;
    }
    .nav-link:hover {
      color: #fff !important;
    }
  }

  .form-container {
    .bg-nav-pills {
      background-color: #3a3a3f;
    }
    .nav-link:not(.active) {
      color: rgba(255,255,255,0.75);
    }
  }

  .btn-outline-primary {
    color: #bfbdff !important;
  }

  .avatar-styled {
    background-color: #fff;
    color: var(--ct-primary);
  }

  .account-user-name {
    color: #908cff !important;
  }

  .btn-primary, .btn-success, .react-select__input {
    color: white !important;
  }

  .breadcrumb-item {
    &:not(.active) a {
      color: var(--ct-link-color) !important;
    }
    &:before,
    &.active {
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }

  .form_group .form_group_control:hover:not(.form_group_control--disabled) {
    background-color: #48515d !important;
  }

  .dropdown-select {
    fill: #8c8c8c !important;
  }

  .layout_header {
    .dropdown {
      button {
        background-color: #27262e !important;
        border-color: #27262e !important;
        color: #fff !important;
        box-shadow: none;
      }
    }
  }

  .help-content {
    .p-accordion-content {
      padding: 6px 0 !important;
      background: transparent !important;
    }
  }

  .logo-name {
    color: #fff !important;
  }

  .p-row-toggler {
    color: #f1f1f1 !important;
  }

  .site-rightbar-ref .p-inputtext {
    color: #fff !important;
    background: #37414d !important;
  }

  .dropzone {
    background-color: #424953;
    border: 2px dashed #717382;
    color: #e2e2e2;
  }

  .helper-text {
    color: #e2e2e2;
  }

  .static-text {
    color: #e2e2e2;
  }

  .p-multiselect-panel {
    .p-multiselect-items .p-multiselect-item:hover {
      background-color: #48515d !important;
    }

    .p-multiselect-items .p-multiselect-item.p-highlight {
      background-color: rgba(90, 105, 235, 0.2) !important;
      color: #d9d9d9;
    }
  }

  td a {
    color: #908cff !important;
  }

  .p-datatable .p-datatable-thead > tr,
  .cp-data-table .p-datatable .p-datatable-thead > tr,
  .p-datatable .p-datatable-tbody > tr:hover {
    background: #2a2932 !important;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none !important;
    border-color: #727cf5 !important;
  }

  .p-radiobutton {
    width: 17px;
    height: 17px;
  }

  .p-radiobutton .p-radiobutton-box {
    border: 2px solid #ced4da;
    background: var(--ct-body-bg);
    width: 17px;
    height: 17px;
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 7px;
    height: 7px;
    background-color: #fff;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #727cf5;
    background: #727cf5;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #727cf5;
    background: #727cf5;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #ced4da;
  }
}

.p-calendar > input {
  border: 1px solid var(--ct-input-border-color);
  background-color: var(--ct-input-bg) !important;

  &::placeholder {
    color: hsl(0, 0%, 50%) !important;
  }
}

.link-download, .link-redirect, u {
  color: var(--ct-link-color) !important;
}

.footer-link {
  color: var(--ct-link-color);
}

.p-paginator .p-paginator-left {
  color: var(--ct-text-color-pagination);
}

.content-page {
  color: var(--ct-body-color) !important;
  background-color: var(--ct-body-bg) !important;
}

.search-wrapper .search-input i {
  color: var(--ct-input-color) !important;
}

.approver-select .p-multiselect-token {
  margin: 2px;
}

.display-hover {
  display: none;
}

.p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  .display-hover {
    display: inline-flex;
  }
}

.react-select__single-value {
  color: #6c757d !important;
}

.modal-header .btn-close {
  margin-right: 0px;
}

.toast {
  background-color: #e6f2ea;
  color: #343c41;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px 12px 0px;
  width: auto;
  height: 48px;

  img {
    margin-right: 10px;
  }
}

.modal-title {
  display: flex;

  img {
    margin-right: 12px;
  }
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--ct-input-border-color);
  background-image: none;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--ct-input-border-color);
}

.p-radiobutton-disabled {
  cursor: not-allowed;
}

.error-toast {
  background-color: #fae8e6;
}

.p-toast-message-content {
  display: flex;
  align-items: center;

  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.layout-detail-form-management-detail .card:nth-child(2) {
  .card-body {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.layout-detail-form-management-detail .card:nth-child(3) {
  .card-body {
    padding-top: 10px;
    padding-bottom: 0px;
  }
}

.dropdown-menu.show {
  max-height: 70vh;
  overflow: auto;
}

.btn-help-form:hover {
  background-color: transparent !important;
  color: #605bff !important;
  border: 1px solid #605bff !important;
}

.btn-help-form {
  border: 1px solid #605bff;
  color: #605bff !important;
}

.rightside-popup {
  .btn-link {
    text-decoration: underline;
    padding: 0 !important;
  }
}

.pointer {
  cursor: pointer !important;
}

.video-intro {
  position: relative;
}

.icon-close-video {
  display: block;
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
}

.p-datatable-tbody {
  .action-column-documents {
    padding: 0.75rem !important;
  }
}

.form-instructions-accordion-header .p-accordion-header-link {
  border-color: #fff !important;
  padding-bottom: 0 !important;
}

.form-instructions-accordion-sub-header {
  padding-left: 10px !important;
}

.form-instructions-accordion-sub-header .p-accordion-header-link {
  border-color: #dee2e6 !important;
}

.form-instructions-accordion-sub-header .p-accordion-header-link .p-accordion-header-text {
  text-decoration: underline;
}

.form-check-input {
  margin-top: 2px !important;
}

.simplebar-content {
  .form_group .row {
    width: auto;
  }
}

.simplebar-content .p-multiselect-panel {
  z-index: 10000 !important;
  left: 0 !important;
  top: 170px !important;
}

.site-rightbar-ref .p-inputtext {
  color: inherit !important;
  background: #37414d !important;
}

.form-container {
  width: 100%;
}

// hot fix font of navigation bar is broken
.mdi-magnify::before {
  content: '\f0349' !important;
}

.dripicons-home:before {
  content: '\e006' !important;
}

.dripicons-suitcase:before {
  content: '\e048' !important;
}

.mdi-chevron-down::before {
  content: '\F0140' !important;
}

.mdi-logout::before {
  content: '\F0343' !important;
}

.mdi-filter::before {
  content: '\F0232' !important;
}

.mdi-circle::before {
  content: '\F0765' !important;
}

.mdi-refresh::before {
  content: '\F0450' !important;
}

.datatable-title {
  text-transform: capitalize !important;
}

.layout_sidebar {
  position: fixed !important;
}

.p-checkbox .p-checkbox-box {
  width: 16px;
  height: 16px;
  background: transparent;
  border: var(--ct-form-check-input-border);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border: var(--ct-form-check-input-border);
  background: transparent;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border: var(--ct-form-check-input-border);
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1.5rem !important;
}

.form-configuration .form_group .form_group_control:not(.form-check) {
  min-height: 17px;
}

.form-configuration .form-info, .detail-info {
  .form_group_control--disabled {
    background-color: transparent !important;
  }
}

.p-datatable .p-checkbox .p-checkbox-box {
  border: var(--ct-form-check-input-border);
}

.p-checkbox {
  align-items: center;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e');
  background-color: var(--ct-form-check-input-checked-bg-color) !important;
  border-color: var(--ct-form-check-input-checked-border-color) !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border: var(--ct-form-check-input-border);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e') !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  display: none;
}

.react-select__multi-value {
  background-color: var(--ct-form-check-input-checked-bg-color) !important;
  color: #fff !important;
}

.react-select__multi-value .react-select__multi-value__label {
  color: #fff !important;
}

.circle-background {
  stroke: #ddd;
}

.circle-background,
.circle-progress {
  fill: none;
}

.tab-card {
  font-size: 14px;
}

.form-check-input:hover {
  cursor: pointer;
}

.rightsidebar-content-height {
  font-size: 14px !important;

  .fit-width-label-container {
    .form_group_control {
      min-width: 250px;
    }
  }

  .p-datepicker {
    top: 80px !important;
    left: 15px !important;
    width: 250px !important;
    font-size: 14px !important;
  }

  .p-datepicker table th {
    padding: 0 !important;
    padding-left: 0.5rem !important;
    font-size: 14px !important;
  }

  .p-datepicker table td {
    padding: 0.125rem 0 !important;
  }

  .p-datepicker table td > span {
    width: 2rem !important;
    height: 2rem !important;
    font-size: 14px !important;
  }

  .p-datepicker table td.p-datepicker-today > span {
    color: #8792f1;
  }

  .p-datepicker table td > span.p-highlight {
    background: #8792f1;
  }

  .p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #333333;
  }
}

.button-action-hover {
  display: none;
}

.button-action-area:hover {
  .button-action-hover {
    display: block;
  }
}

.btn:not(.btn-lg):not(.btn-sm):not(.btn-refresh) {
  height: auto !important;
}

.background-transparent {
  background: var(--ct-card-bg) !important;
}

.p-selection-column {
  display: none;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.p-datatable-row-expansion > td,
.p-datatable-row-expansion > td > div {
  max-width: 100% !important;
  width: 100% !important;
  padding: 0 0 0 12px !important;
}

.cp-data-table .p-datatable .p-datatable-thead > tr > th {
  min-height: 48.5px;
  border-bottom: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
  border-bottom: none !important;
}

.p-datatable .p-datatable-thead > tr {
  background: #efefff;
  border-bottom: 1px solid var(--ct-primary);
}

.cp-data-table .p-datatable .p-datatable-thead > tr {
  background: #efefff;
  border-bottom: 1px solid var(--ct-primary);
}

.p-datatable-row-expansion .p-datatable-resizable .p-datatable-tbody > tr > td {
  overflow: inherit;
  white-space: break-spaces;
}

.p-datatable .p-datatable-header {
  background: transparent !important;
  color: rgba(255, 255, 255, 0.6);
  border: none;
  padding: 0;
}

.p-datatable-emptymessage > td {
  max-width: 100% !important;
  text-align: center;
  justify-content: center;
}

.modal-confirm-show-tour {
  .modal-dialog-centered {
    width: 370px;
  }
}

.reactour__helper {
  max-width: 360px !important;
  padding: 16px 22px !important;
}

.reactour__helper > button {
  top: 16px;
  right: 16px;
}

.mini-onboarding-video {
  cursor: pointer;
}

.p-datatable .p-datatable-tbody > tr:not(.p-datatable-row-expansion) > td,
th {
  word-break: break-word !important;
  padding: 12px 16px !important;
}

.p-datatable .p-datatable-tbody > .p-datatable-row-expansion .p-datatable-tbody > .p-selectable-row td:nth-child(2) {
  padding-right: 0 !important;
  padding-left: 8px !important;
}

.p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable-tbody>.p-selectable-row td:nth-child(2),
.p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable-tbody>.p-selectable-row td:nth-child(3) {
  padding-left: 0 !important;
}

.p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable-tbody>.p-selectable-row td:nth-child(2),
.p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable-tbody>.p-selectable-row td:nth-child(3) {
  padding-left: 0 !important;
}

.p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion>td {
  padding-left: 4px !important;
}

.p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion>td>.p-sub-table {
  padding-left: 0 !important;
}

.p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable .p-datatable-tbody>.p-datatable-row-expansion .p-datatable-tbody>.p-selectable-row td:nth-child(2)>div {
  padding-left: 12px !important;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .welcome-modal {
      width: fit-content !important;
    }
  }
}

.addButton {
  margin-right: 7px;
}

.dripicons-plus:before {
  margin-bottom: 2px;
}

.sidebar-search {
  .form-control {
    background-color: transparent !important;
  }

  span.search-icon {
    color: var(--layout-menu-item);
  }
}

.mode-selection {
  position: fixed;
  right: 18rem;
  top: 1rem;
  z-index: 100;
  cursor: pointer;
  width: auto;
}

.display-content {
  display: contents;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-row-expansion-parent td {
  background-color: var(--ct-card-bg);
}

.left-tab-content {
  line-height: 180%;

  .tab-item-color {
    color: #0085bd;
    margin-left: -16px;
  }
}

.left-tab-content li {
  margin: 1.5rem 0px;
}

.modal-scroll {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
  margin-top: 1rem;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #c1c1c1;
  }
}

.modal-term-of-use-container {
  .modal-content {
    border-radius: 12px;
  }

  .modal-dialog {
    max-width: 60%;
  }

  .modal-footer {
    justify-content: flex-end;
  }

  .reject-btn {
    color: #0085bd;
    font-weight: 600;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
  }

  .reject-btn:hover {
    background: #c1c1c1;
  }
}

.footer-link {
  cursor: pointer;
  text-decoration: underline;
  color: #605bff;
}

.privacy-container {
  font-size: 1.1rem;

  table,
  td,
  th,
  tr {
    border: 1px solid #000;
    padding: 4px;
  }

  li {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.privacy-body {
  .card-body {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.layout-body-scroll {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    background: #c1c1c1 !important;
  }
}

.pre {
  font-family: 'Nunito', sans-serif;
}

.account-user-name {
  color: var(--ct-link-color) !important;
}

.min-height-none {
  min-height: 0px !important;
}

.sidebar .sidebar_header {
  background-color: var(--layout-primary-system-color) !important;
}

.nav-user {
  border: none !important;
  background-color: transparent !important;
}

body[data-sidebar-theme=dark] .layout ::-webkit-scrollbar-thumb {
  border: none !important;
  background: #c1c1c1;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}
